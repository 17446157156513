import React, { Suspense, useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import Portfolios from "../pages/Portfolios";
import Resumes from "../pages/Resumes";
import ProgressiveImage from "react-progressive-image";


function Home({ lightMode }) {
  const names = [
    'Web Developer', 'UI/UX Designer', 'App Developer', 'Mobile App Developer', 'Graphic Designer', 'Digital Designer', 'Frontend Developer', 'React Developer'
  ]
  const [services, setServices] = useState("Web Developer");

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setServices(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 2000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <Layout>

      <Helmet>
        <title>Kimberly Smith</title>
        <meta
          name="description"
          content="Kimberly Smith Portfolio"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <nav className="topmenu">
          <div className="social-menu">
            <div className="resume_button"><a target="_blank" href="/files/smith_kimberly_resume.pdf"> Download Resume</a></div>
            <a target="_blank" href="https://linkedin.com/in/kimberlylynnsmith"><img src="/images/linkedin.png"></img></a>
          </div>
        </nav>
        <nav className="bottommenu">
          <div className="menu">
            <div className="menu__bg button first">
              <a href="#portfolio"> PORTFOLIO</a>

            </div>
            <div className="menu__bg button">
              <a href="#skills"> SKILLS</a>

            </div>
            <div className="menu__bg button last">
              <a href="#resume">WORK EXPERIENCE</a>
            </div>
          </div>
        </nav>


        <div className="mi-home-area mi-padding-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="mi-home-content">
                  <div className="mi-about-image">
                    <ProgressiveImage
                      src="/images/headshot.png"
                      placeholder="/images/headshot.png"
                    >
                      {(src) => (
                        <img
                          src={src}
                          alt="aboutimage"
                          onClick={() => handleToggler(!toggler)}
                        />
                      )}
                    </ProgressiveImage>
                  </div>
                </div>
              </div>
              <div style={{ "display": "flex", "justify-content": "center", "align-items": "center" }} className="col-lg-7">
                <div className="mi-home-content">
                  <h1>
                    WELCOME TO MY PORTFOLIO.
                  </h1>
                  <h3 className="name">My name is <strong>Kimberly Smith</strong> and I'm a </h3>
                  <h2 className="job"><span>{services}</span></h2>

                </div>
              </div>
            </div>
          </div>



        </div>

      </Suspense>
      <Portfolios />

      <Resumes />
    </Layout>
  );
}

export default Home;

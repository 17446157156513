import React from "react";


function Resume(props) {

  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{props.year}</h6>
        <h6 className="mi-resume-year">{props.endyear}</h6>
      </div>
      <div className="mi-resume-details">

        <h5 className="mi-resume-company">{props.company}</h5>
        <h6>{props.title}</h6>

        {props.bullets}

        <h6>{props.title2}</h6>
        {props.bullets2}

      </div>
    </div>
  );
};

export default Resume;

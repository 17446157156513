import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";

import Home from "./pages/Home";
import Notfound from "./pages/Notfound";


function App() {
  const [lightMode, setLightMode] = useState(true); // Made it true if you want to load your site light mode primary

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home lightMode={lightMode} />} />

        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";

function Sunforgedotcom() {

    return (
        <div className="bulbs modalContent">
            <h1>Tripodi Quality Consulting Website</h1>
            <div className="row">
                <div className="col-md-7 website-talk">
                    <p>I created tripodiqualityconsulting.com based on a request for a website with a dark color theme, an appointment scheduler on the contact page, and the ability to log on and edit content easily without any coding knowledge. I set up a Wordpress site with a drag-and-drop editor so it could be edited in future without a web developer. In the end, we created a final product that was sleek, professional and a great tool to boost my client's business.
                    </p>
                    <h6>Tools used to build this website:</h6>
                    <div className="col-md-12">
                        <div style={{ "float": "left" }} className="col-md-6">
                            <ul>
                                <li>Wordpress</li>
                                <li>PHP</li>
                                <li>Elementor</li>
                                <li>Javascript</li>
                                <li>HTML</li>
                                <li>CSS</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ "text-align": "center" }} className="col-md-5">
                    <div style={{ 'max-width': '600px', 'padding-top': '5vh', 'padding-left': '5vw', 'padding-right': '5vw' }}><a target="_blank" href="https://tripodiqualityconsulting.com" ><img src="/images/_tripodi_website_desktop.png" /></a></div>
                    <button className="websiteButton"><a target="_blank" href="https://tripodiqualityconsulting.com">Visit Site</a></button>
                </div>

            </div>

        </div>
    );
};

export default Sunforgedotcom;
import React from "react";

function BT_Connect() {

    return (
        <div className="bulbs modalContent">
            <h1>BT Connect Mobile App</h1>
            <div className="row">
                <div style={{ "text-align": "center" }} className="col-md-4">

                    <div style={{ 'max-width': '600px', 'padding-left': '1vw', 'padding-right': '0.5vw', "text-align": "center" }}><a target="_blank" href="https://apps.apple.com/us/app/blue-sky-energy-bt-connect/id1566212091" ><img src="/images/homescreen.png" /></a></div>
                    <button className="websiteButton" style={{ "float": "none" }}><a href="https://apps.apple.com/us/app/blue-sky-energy-bt-connect/id1566212091">View in the App Store</a></button>
                </div>
                <div className="col-md-8 website-talk">
                    <p>The BT Connect app is available for iOS and Android devices and was built primarily with React Native. It uses Bluetooth Low Energy (BLE) to connect with it's partner bluetooth adapter, then uses this connection to send and recieve datapoints about the user's off grid solar system. It provides the user with an intuitive dashboard, graphs and tables illustrating the history of the system, and acts as a remote to change settings on the hardware instantly.
                    </p>
                    <h6>Tools used to build this App:</h6>
                    <div className="col-md-12">
                        <div style={{ "float": "left" }} className="col-md-6">
                            <ul>
                                <li>React Native</li>
                                <li>React Redux</li>
                                <li>Redux Saga</li>
                            </ul>
                        </div>
                        <div style={{ "float": "left" }} className="col-md-6">
                            <ul>
                                <li>XCode</li>
                                <li>Android Studio</li>
                                <li>Apple TestFlight</li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container row app_tour">
                <h2 style={{ "text-align": "center" }}>App Highlights</h2>
                <div class="app_tour_section col-md-12">
                    <div class="app_tour_text col-md-6">
                        <div class="app_tour_title">
                            Real-time Data
                        </div>
                        <span>The BT Connect app receives over 20 different datapoints from the bluetooth adapter, and displays them on the dashboard and data pages. These pages update instantly, allowing the user to keep track of values that can change every second. It features icons that change along with the data and the ability to hide data to reduce crowding.</span>
                    </div>
                    <div class="app_tour_gif col-md-5">
                        <img width="100%" height="100%" src="/images/app-data-preview.gif" />
                    </div>

                </div>
                <div class="app_tour_section col-md-12">
                    <div class="app_tour_text ">
                        <div class="app_tour_title historical_data_text">
                            Historical Trends
                        </div>

                        <p className="historical_data_text">The BT Connect also recieves a snapshot of the adapter's data every five minutes, which is then saved to the phone and accessible for as long as the user would like to keep it. The app displays the data in beautiful, snappy graphs and tables. This makes the app the perfect tool for troubleshooting and record keeping.</p>

                    </div>
                    <div class="app_tour_gif col-md-5">
                        <img loading="lazy" width="100%" height="100%" src="/images/historical_data_preview.gif" />

                    </div>

                </div>

                <div class="app_tour_section col-md-12">

                    <div class="app_tour_text col-md-6">
                        <div class="app_tour_title">
                            System Setpoints
                        </div>
                        <span>The BT Connect also acts as a remote control, allowing the user to change their system setpoints instantly. Setpoints are intuitively organized for a quick and easy setting experience. </span>
                    </div>
                    <div class="app_tour_gif col-md-5">
                        <img width="100%" src="/images/setpoint_preview.gif" />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default BT_Connect;

import React from "react";
import { useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";





function Infographics() {
    const photos = [
        { src: "/images/btc_banner.png", width: 1007, height: 2437 },
        { src: "/images/Info.jpg", width: 918, height: 2220 },
        { src: "/images/LEDInfo.jpg", width: 640, height: 1498 },
        { src: "/images/Amazon_Black_Friday_Banner_15.jpg", width: 1500, height: 300 },
        { src: "/images/UtilityRates.png", width: 1295, height: 4637 },
        { src: "/images/BSE_banner.png", width: 1188, height: 2187 },
        { src: "/images/Gen_banner.png", width: 924, height: 1701 },
        { src: "/images/BulbMeasurement.jpg", width: 1112, height: 516 },


    ];


    const [index, setIndex] = useState(-1);



    return (
        <div className="modalContent">
            <h2>Infographics and Marketing Banners</h2>
            <PhotoAlbum spacing={10} photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />

        </div>
    );
};

export default Infographics;
import React from "react";
import Portfolio from '../components/Portfolio';
import BT_Connect from "./Portfolio/BT-Connect";
import Infographics from "./Portfolio/Infographics";
import SunforgeBranding from "./Portfolio/SunforgeBranding";
import Sunforgedotcom from "./Portfolio/Sunforgedotcom";
import Bulbsdotcom from "./Portfolio/Bulbsdotcom";
import BseLogo from "./Portfolio/BseLogo";
import TripodiQualityConsulting from "./Portfolio/TripodiQualityConsulting";




function PortfoliosView(props) {

  const photos = [
    { src: "/images/Info.jpg", width: 918, height: 2220 },
    { src: "/images/LEDInfo.jpg", width: 640, height: 1498 },
    { src: "/images/Amazon_Black_Friday_Banner_15.jpg", width: 1500, height: 300 },
    { src: "/images/UtilityRates.png", width: 1295, height: 4637 },
    { src: "/images/BSE_banner.png", width: 1188, height: 2187 },
    { src: "/images/Gen_banner.png", width: 924, height: 1701 },
    { src: "/images/BulbMeasurement.jpg", width: 1112, height: 516 },
  ];

  const programming = ([{
    title: "BT Connect",
    //subtitle: "A mobile app intuitively designed for customers who want to add bluetooth monitoring to their solar setup.",
    imageUrl: '/images/btc_preview.png',
    largeImageUrl: "/images/btc_preview.png",
    content: <BT_Connect />

  },
  {
    title: "Sunforgellc.com",
    //subtitle: "An ecommerce experience and information hub for OEM customers and individuals looking to go off-grid.",
    imageUrl: '/images/website_header.png',
    largeImageUrl: '/images/website_header.png',
    content: <Sunforgedotcom />
  },
  {
    title: "Tripodiqualityconsulting.com",
    //subtitle: "An ecommerce experience and information hub for OEM customers and individuals looking to go off-grid.",
    imageUrl: '/images/tripodi_website_header.png',
    largeImageUrl: '/images/tripodi_website_header.png',
    content: <TripodiQualityConsulting />
  }

  ]
  );

  const design = ([
    {
      title: "Sunforge LLC Branding",
      //subtitle: "Various pieces of an on-going project to merge two brands, Blue Sky Energy and Genasun, into a new identity.",
      imageUrl: "/images/sunforge_branding_preview.png",
      largeImageUrl: "/images/sunforge_branding_preview.png",
      content: <SunforgeBranding />
    },
    {
      title: "Infographics and Marketing Posters",
      //subtitle: "Using design to educate and inform."
      imageUrl: '/images/infographic_preview.png',
      largeImageUrl: "/images/infographic_preview.png",
      content: <Infographics />,
    },
    {
      title: "Logo Design",
      // subtitle: "A logo refresh for Bulbs.com.",
      imageUrl: '/images/bulbs_logo.png',
      largeImageUrl: "/images/bulbs_logo.png",
      content: <Bulbsdotcom />
    },


  ]
  );

  if (props.type == "programming") {

    return (
      <div className="row mt-30-reverse">
        {programming.map(programming => (
          <div className="col-lg-4 col-md-6 col-12 mt-30" >
            <Portfolio title={programming.title} content={programming.content} subtitle={programming.subtitle} imageUrl={programming.imageUrl} />
          </div>
        ))}
      </div>
    );
  }
  else {
    return (
      <div className="row mt-30-reverse">
        {design.map(design => (
          <div className="col-lg-4 col-md-6 col-12 mt-30" >
            <Portfolio title={design.title} content={design.content} subtitle={design.subtitle} imageUrl={design.imageUrl} />
          </div>
        ))}
      </div>
    );
  }
}

export default PortfoliosView;

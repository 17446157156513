import React from "react";

function Bulbsdotcom() {

    return (
        <div className="bulbs modalContent">
            <h1>Bulbs.com Logo Refresh</h1>
            <p>Due to the changes in the light bulb market, Bulbs.com wanted to redesign their logo to represent an LED rather than an outdated CFL bulb. They had a temporary design, shown below, and asked me to take a shot at coming up with the official design. Bulbs.com has been using the new logo for over five years.</p>
            <div><img src="/images/bulbs_logo_transition.png" /></div>
            <h1>Blue Sky Energy Logo Redesign</h1>
            <div className="bselogo">
                <p>The BSE logo was very outdated and needed a refresh. The goal was to create a new logo that would compliment the company's other brand, Genasun, while upholding some of the same elements of the old design.</p>
                <div><img src="/images/bse_logo_transition.png" /></div>
                <p>I was also tasked with incorporating the new company name, Sunforge, into the logos.</p>
                <div><img src="/images/logos.png" /></div>
            </div>
        </div>
    );
};

export default Bulbsdotcom;
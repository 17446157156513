import React from "react";

function PortfolioModal({ handleClose, show, children }) {


    const showHideClassName = show ? "modal display-block" : "modal display-none";


    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <button className="closeButton" type="button" onClick={handleClose}>
                    x
                </button>
                {children}
            </section>
        </div>
    );
};

export default PortfolioModal;


import FsLightbox from "fslightbox-react";
import React, { useState } from "react";
import * as Icon from "react-feather";
import ProgressiveImage from 'react-progressive-image';
import PortfolioModal from './PortfolioModal.js';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function Portfolio(props) {
  const [toggler, setToggler] = useState(false);
  const [show, setShow] = useState(false);

  const handleToggler = (value) => {
    setToggler(value);
  }
  const closeModal = () => {
    setShow(false);
  }

  const showModal = () => {
    setShow(true);
  }

  const [index, setIndex] = useState(-1);

  const photos = [
    { src: "/images/btc_banner.png", width: 1007, height: 2437 },

    { src: "/images/Info.jpg", width: 918, height: 2220 },
    { src: "/images/LEDInfo.jpg", width: 640, height: 1498 },
    { src: "/images/Amazon_Black_Friday_Banner_15.jpg", width: 1500, height: 300 },

    { src: "/images/UtilityRates.png", width: 1295, height: 4637 },
    { src: "/images/BSE_banner.png", width: 1188, height: 2187 },
    { src: "/images/Gen_banner.png", width: 924, height: 1701 },
    { src: "/images/BulbMeasurement.jpg", width: 1112, height: 516 },


  ];



  if (props.title == "Infographics and Marketing Posters") {

    return (

      <div className="mi-portfolio mi-portfolio-visible">
        <div onClick={({ index }) => setIndex(0)} className="mi-portfolio-image">
          <img
            src={props.imageUrl}
          >
          </img>
          <ul>
            <li>
              <button onClick={({ index }) => setIndex(0)}>View
              </button>
            </li>
            {props.url ? <li>
              <a rel="noopener noreferrer" target="_blank" href={props.url}>
              </a>
            </li> : null}
          </ul>
        </div>
        {!props.url ? <h5>{props.title}</h5> : <h5>
          <a rel="noopener noreferrer" target="_blank" href={props.url}>
            {props.title}
          </a>
        </h5>}
        {props.subtitle ? <h6>{props.subtitle}</h6> : null}
        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />

      </div>
    );


  }
  else {
    return (
      <div className="mi-portfolio mi-portfolio-visible">
        <PortfolioModal handleClose={closeModal} children={props.content} show={show}></PortfolioModal>
        <div onClick={() => showModal()} className="mi-portfolio-image">
          <img
            src={props.imageUrl}
          >
          </img>
          <ul>
            <li>
              <button onClick={() => handleModal(!show)}>View
              </button>
            </li>
            {props.url ? <li>
              <a rel="noopener noreferrer" target="_blank" href={props.url}>
              </a>
            </li> : null}
          </ul>
        </div>
        {!props.url ? <h5>{props.title}</h5> : <h5>
          <a rel="noopener noreferrer" target="_blank" href={props.url}>
            {props.title}
          </a>
        </h5>}
        {props.subtitle ? <h6>{props.subtitle}</h6> : null}

      </div>
    );

  }


}

export default Portfolio;

import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TrackVisibility from "react-on-screen";
import Section from "../components/Section";
import Progress from "../components/Progress";
import Resume from "../components/Resume";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";
import Spinner from "../components/Spinner";

function Resumes() {


  const work_experience = ([
    {
      company: "Strive Tech",
      title: "Senior Fullstack Engineer",
      year: "October 2023 - ",
      endyear: 'June 2024',
      bullets: <ul><li>Developed a new mobile application by integrating features from a legacy app with modern design and functionality.</li><li>Built new features in mobile applications, utilizing BLE protocols to gather, visualize, and upload biometric data via GraphQL API.</li><li>Enhanced the reusable component library, collaborating closely with the UX team to implement design specifications.</li><li>Collaborated in design meetings, balancing user experience goals with project deadlines to deliver high-quality results.</li><li>Provided consistent and detailed code reviews, ensuring adherence to coding standards and improving code quality across the team.</li><li> Maintained a comprehensive test suite to ensure application stability and quality.</li><li>Executed iOS app update releases, ensuring smooth deployments and compliance with App Store requirements.</li><li>Worked with non-technical stakeholders to translate business requirements into actionable development specifications.</li></ul>,
    },
    {
      company: "Sunforge LLC",
      title: "Frontend Design Engineer",
      year: "July 2018 -",
      endyear: 'October 2023',
      title2: "Designer",
      bullets: <ul><li>Designed and developed an iOS/Android app that interfaces with product hardware via Bluetooth, using React Native, Redux, Xcode, and Android Studio.</li><ul><li>Built app to receive data, transmit setpoints, and display information to customers.</li><li>Succesfully distributed app though Apple App Store and Google Play Store.</li></ul><li>Configured and maintained servers that hosted company websites and databases, using AWS EC2, NGINX and MYSQL</li><li>Designed and built an ecommerce website using Wordpress, Javascript, PHP, HTML, and CSS</li><ul><li> Wrote custom plugins and theme alterations to address business' unique requirements.</li><li>Transitioned legacy ecommerce store by migrating customer and order data with SQL.</li></ul></ul>,
      bullets2: <ul><li>Created digital assets, marketing material and business cards to unite the Blue Sky Energy and Genasun brands under one company name, Sunforge.</li><li>Designed and developed a Sunforge website to replace legacy Blue Sky Energy and Genasun websites, showcasing the two product lines under one brand</li><li> Redesigned the Blue Sky Energy logo.</li><li>Designed physical and digital assets for products: manuals, datasheets, silkscreens, box labels, landing pages, and Amazon storefront designs.</li></ul>
    },
    {
      company: "NRGTree",
      title: "Solar Operations Intern",
      year: "Spring 2018",
      bullets: <ul><li>Redesigned company website with Photoshop, HTML, CSS, and Javascript.</li><li>Created infographics to educate customers about solar energy and financing.</li><li>Designed marketing material such as banners and brochures to distribute to partner credit unions.</li></ul>,
      bullets2: "",
    },
    {
      company: "Bulbs.com",
      title: "Creative Design Intern",
      year: "Summer 2017",
      bullets: <ul><li>Redesigned company logo</li><li>Created marketing emails, website banners and other digital assets with Adobe Creative Cloud.</li><li>Designed and developed functional webpage mockups.</li></ul>,
      bullets2: ""
    },
    {
      company: "Institute for Training and Development",
      title: "Program Assistant",
      year: "August 2016 -",
      endyear: 'June 2018',
      bullets: <ul><li>Collaborated on redesigning new company website</li><li>Assisted with transportation and logistics for programs with international participants. </li><li>Managed Word and Excel documents</li></ul>,
      bullets2: ""
    },
  ]);

  const skills = ([
    {
      title: "HTML",
      percentage: 95
    },
    {
      title: "CSS",
      percentage: 95
    },
    {
      title: "React & React Native",
      percentage: 95
    },
    {
      title: "JavaScript",
      percentage: 95
    },
    {
      title: "TypeScript",
      percentage: 95
    },
    {
      title: "PHP",
      percentage: 95,
    },
    {
      title: "SQL",
      percentage: 80,
    },
    {
      title: "R",
      percentage: 50,
    },
    {
      title: "Java",
      percentage: 80,
    },
    {
      title: "Wordpress",
      percentage: 95,
    },
    {
      title: "Python",
      percentage: 80,
    },
    {
      title: "Amazon AWS EC2",
      percentage: 80,
    },
    {
      title: "XCode",
      percentage: 80,
    },
    {
      title: "Bluetooth Low Energy (BLE)",
      percentage: 80,
    },
    {
      title: "GraphQL",
      percentage: 80,
    },
    {
      title: "Apollo",
      percentage: 80,
    },
    {
      title: "InDesign",
      percentage: 95,
    },
    {
      title: "Photoshop",
      percentage: 95,
    },
    {
      title: "Figma",
      percentage: 80,
    },
    {
      title: "Illustrator",
      percentage: 50,
    },
    {
      title: "Android Studio",
      percentage: 50,
    },
    {
      title: "Maya",
      percentage: 50,
    },

  ]);
  return (
    <div id="skills" style={{ padding: '5%' }}>
      <Suspense fallback={<Spinner />}>
        <div className="mi-skills-area mi-section mi-padding-bottom row">
          <div className="container">
            <div class="mi-sectiontitle"><h2 style={{ "color": "#055160", "text-align": "center" }}>Programming and Software Experience</h2></div>
            <div className="row">
              <div className="col-lg-4">
                <Smalltitle title="Highly Experienced" />
                <div className="mi-skills">
                  <div className="row mt-30-reverse">

                    {skills.map((skills, index) => {

                      return skills.percentage == 95 ?
                        <TrackVisibility

                          key="HTML"
                        >
                          <Progress key={index} title={skills.title} percentage={skills.percentage} />
                        </TrackVisibility>
                        :
                        <div></div>



                    })}



                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <Smalltitle title="Moderately Experienced" />
                <div className="mi-skills">
                  <div className="row mt-30-reverse">

                    {skills.map((skills, index) => {

                      return skills.percentage == 80 ?
                        <TrackVisibility

                          key="HTML"
                        >
                          <Progress key={index} title={skills.title} percentage={skills.percentage} />
                        </TrackVisibility>
                        :
                        <div></div>



                    })}



                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <Smalltitle title="Somewhat Experienced" />
                <div className="mi-skills">
                  <div className="row mt-30-reverse">

                    {skills.map((skills, index) => {

                      return skills.percentage == 50 ?

                        <TrackVisibility

                          key="HTML"
                        >
                          <Progress key={index} title={skills.title} percentage={skills.percentage} />
                        </TrackVisibility>
                        :
                        <div></div>



                    })}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mi-resume-area mi-section mi-padding-top ">
          <div id="resume" className="container">
            <Sectiontitle title="Work Experience" />
            <div className="mi-resume-wrapper">
              {work_experience.map((work_experience, index) => (
                <Resume key={index} endyear={work_experience.endyear} title={work_experience.title} company={work_experience.company} year={work_experience.year} bullets={work_experience.bullets} title2={work_experience.title2} year2={work_experience.year2} bullets2={work_experience.bullets2} />
              ))}

            </div>

          </div>
        </div>
      </Suspense>
    </div>
  );
}

export default Resumes;

import React from "react";

function Sunforgedotcom() {

    return (
        <div className="bulbs modalContent">
            <h1>Sunforge LLC Ecommerce Website</h1>
            <div className="row">
                <div className="col-md-7 website-talk">
                    <p>Before I created this website, Sunforge used four different websites: one for ecommerce, one for each of their two brands, and one to describe what the company was. The goal was to consolidate these four into a beautiful, updated website that showcased both brands, explained the relationship between Sunforge and these brands, and provided an inuitive ecommerce experience for retail and OEM customers. An additional goal was to make the website easy to maintain in the future for non-technical employees. This required a significant amount of custom code, written to allow every component of the website to be edited on the Wordpress dashboard.
                    </p>
                    <h6>Tools used to build this website:</h6>
                    <div className="col-md-12">
                        <div style={{ "float": "left" }} className="col-md-6">
                            <ul>
                                <li>AWS EC2</li>
                                <li>AWS Route53</li>
                                <li>Nginx</li>
                                <li>SQL</li>
                                <li>Wordpress</li>
                            </ul>
                        </div>
                        <div style={{ "float": "left" }} className="col-md-6">
                            <ul>
                                <li>Woocommerce</li>
                                <li>PHP</li>
                                <li>Javascript</li>
                                <li>HTML</li>
                                <li>CSS</li>
                            </ul>
                        </div>
                    </div>


                </div>
                <div style={{ "text-align": "center" }} className="col-md-5">
                    <div style={{ 'max-width': '600px', 'padding-top': '5vh', 'padding-left': '5vw', 'padding-right': '5vw' }}><a target="_blank" href="https://sunforgellc.com" ><img src="/images/website_desktop.png" /></a></div>
                    <button className="websiteButton"><a target="_blank" href="https://sunforgellc.com">Visit Site</a></button>
                </div>

            </div>

        </div>
    );
};

export default Sunforgedotcom;
import React from "react";

function SunforgeBranding() {

    return (
        <div className="modalContent">
            <div className="col-lg-12">
                <h1>Sunforge Branding</h1>
                <p>Before 2018, many customers wouldn't know that there was one company behind the Blue Sky Energy and Genasun products. They had separate websites and very different marketing materials. I contributed to creating a cohesive style guide and set of marketing material, including a new website, that would bring the two brands together and inform customers about the company that was behind the brands they knew.  </p>
                <div className="row">
                    <div className="col-lg-12 branding-item">
                        <h2>Posters for Trade Shows</h2>
                        <img src="/images/branding-banners.png" />

                    </div>
                </div>
            </div>
            <div className=" branding-item col-lg-12">
                <h2>Business Card Design</h2>

                <img src="/images/business-cards.png" />

            </div>
            <div className=" branding-website col-lg-12">
                <h2>
                    Digital Banners
                </h2>

                <div className="col-lg-6">
                    <img src="/images/bse-digital-banner.png" />
                </div>
                <div className="col-lg-6">
                    <img src="/images/genasun-digital-banner.png" />
                </div>


            </div>
            <div className="branding-website col-lg-12">
                <h2>Matching Webpages</h2>
                <div className="col-lg-6">
                    <img src="/images/blue-sky-brand-page.png" />
                </div>
                <div className="col-lg-6">
                    <img src="/images/genasun-brand-pagee.png" />
                </div>
            </div>

        </div>
    );
};

export default SunforgeBranding;
import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Pagination from "../components/Pagination";
import PortfoliosView from "../components/PortfoliosView";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";

function Portfolios() {


  return (
    <div id="portfolio" style={{ padding: '5%' }}>
      <Suspense fallback={<Spinner />}>
        <div id="portfolio" className="mi-about mi-section mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Selected Software Work" />
            <PortfoliosView type={"programming"} />
          </div>
        </div>
        <div id="portfolio" className="mi-about mi-section">
          <div className="container">
            <Sectiontitle title="Selected Design Work" />
            <PortfoliosView type={"design"} />
          </div>
        </div>
      </Suspense>
    </div>
  );
}

export default Portfolios;
